import React from 'react';

import classNames from 'classnames';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Button from '@helsenorge/designsystem-react/components/Button';
import HighlightPanel from '@helsenorge/designsystem-react/components/HighlightPanel';
import List from '@helsenorge/designsystem-react/components/List';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import { getErRepresentasjonViaFullmakt, hasScope } from '@helsenorge/framework-utils/hn-user';
import { getSamtykke, Samtykke } from '@helsenorge/framework-utils/samtykke-utils';

import styles from './styles.module.scss';

export const getShowSavnerDuTjenester = (): boolean => {
  const samtykke = getSamtykke();

  // Bruker er ikke logget på
  if (!isAuthorized()) {
    return false;
  }

  // Ved fullt samtykke er alle tjenester tilgjengelige
  if (samtykke === Samtykke.Full) {
    return false;
  }

  // Skal ikke vise dersom innbygger ikke kan endre samtykkenivå
  if (!hasScope('inngripendeforpersonvernet')) {
    return false;
  }

  return true;
};

export interface Props {
  resources: HNCoreFrontendWebForside;
}

const SavnerDuTjenester: React.FunctionComponent<Props> = ({ resources }: Props) => {
  const samtykke = getSamtykke();

  if (!getShowSavnerDuTjenester()) {
    return null;
  }

  return (
    <HighlightPanel
      size="fluid"
      className={classNames(styles['savnerdutjenester'], getFeatureToggle('NyForside2025') && styles['savnerdutjenester--no-margin-top'])}
    >
      <Title margin={0} appearance="title3" htmlMarkup="h3">
        {resources.savnerDuTjenesterTitle}
      </Title>
      <p className={styles.savnerdutjenester__description}>{resources.savnerDuTjenesterDescription}</p>
      <List>
        {samtykke === Samtykke.Basis && <List.Item>{resources.savnerDuTjenesterPasientjournal}</List.Item>}
        <List.Item>{resources.savnerDuTjenesterLegemidler}</List.Item>
        <List.Item>{resources.savnerDuTjenesterTimeavtaler}</List.Item>
        <List.Item>{resources.savnerDuTjenesterPasientreiser}</List.Item>
        <List.Item>{resources.savnerDuTjenesterDialog}</List.Item>
      </List>
      <Spacer size="l" />
      <Button htmlMarkup="a" href="/personverninnstillinger/samtykker">
        {getErRepresentasjonViaFullmakt() ? resources.savnerDuTjenesterFullmektigButton : resources.savnerDuTjenesterButton}
      </Button>
    </HighlightPanel>
  );
};

export default SavnerDuTjenester;
