import * as React from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';

interface NotificationPanelWrapperProps {
  children: React.ReactNode;
  borderBottom?: boolean;
}

const NotificationPanelWrapper: React.FC<NotificationPanelWrapperProps> = props => (
  <div className={cn(styles['notificationpanel-container'], props.borderBottom && styles['notificationpanel-container--border-bottom'])}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className={styles['notificationpanel-container__content']}>{props.children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default NotificationPanelWrapper;
