import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import PageNotFound from '@helsenorge/core-framework/page-not-found';
import PageRoot from '@helsenorge/core-framework/pageroot';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';

import Forside from './forside';
import ForsideV2 from './forside-v2';
import { store } from './store';
import UtloggetFraOIDC from './utlogget-fra-oidc/index';

const router = createBrowserRouter([
  {
    path: '/oidc/signedout',
    element: (
      <PageRoot noHjelpeskuff noBreadcrumb noDriftspanel hasFullwidth hasPageHeader={false}>
        <UtloggetFraOIDC />
      </PageRoot>
    ),
  },
  {
    path: '/velg-person',
    element: (
      <PageRoot noHjelpeskuff noBreadcrumb hasFullwidth hasPageHeader={false}>
        {getFeatureToggle('NyForside2025') ? <ForsideV2 /> : <Forside />}
      </PageRoot>
    ),
  },
  {
    path: '/',
    element: (
      <PageRoot noHjelpeskuff noBreadcrumb hasFullwidth hasPageHeader={false}>
        {getFeatureToggle('NyForside2025') ? <ForsideV2 /> : <Forside />}
      </PageRoot>
    ),
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

async function enableMocking(): Promise<ServiceWorkerRegistration | void> {
  if (import.meta.env.MODE !== 'mocks') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('main-content-wrapper')!).render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  );
});
