import Articles from '@helsenorge/core-cms/articles';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';

import { useGetForsideQuery } from '../store/contentApi';

import styles from './styles.module.scss';

const ArticlesContainer: React.FC = () => {
  const { data: forside } = useGetForsideQuery(LanguageLocales.NORWEGIAN);

  if (!forside) {
    return null;
  }

  return (
    <div className={styles.articlescontainer}>
      <div className="container">
        <div className="row">
          <div className="col">
            <Articles title={forside.articles.articlesTitle} articles={forside.articles.articleList} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesContainer;
