import React from 'react';

import classNames from 'classnames';

import Button from '@helsenorge/designsystem-react/components/Button';
import HighlightPanel from '@helsenorge/designsystem-react/components/HighlightPanel';
import List from '@helsenorge/designsystem-react/components/List';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';

import styles from '../savner-du-tjenester/styles.module.scss';

export interface Props {
  title?: string | null;
  description?: string | null;
  bodyTexts?: string[] | null;
  buttonText?: string | null;
}

const SavnerDuTjenester: React.FunctionComponent<Props> = props => {
  return (
    <HighlightPanel size="fluid" className={classNames(styles['savnerdutjenester'], styles['savnerdutjenester--no-margin-top'])}>
      <Title margin={0} appearance="title3" htmlMarkup="h3">
        {props.title}
      </Title>
      <p className={styles.savnerdutjenester__description}>{props.description}</p>
      <List>{props.bodyTexts?.map(text => <List.Item key={text}>{text}</List.Item>)}</List>
      <Spacer size="l" />
      <Button htmlMarkup="a" href="/personverninnstillinger/samtykker">
        {props.buttonText}
      </Button>
    </HighlightPanel>
  );
};

export default SavnerDuTjenester;
