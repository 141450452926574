import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';

import Languages from '@helsenorge/core-utils/constants/languages';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { getAssetsUrl } from '@helsenorge/framework-utils/hn-page';
import { getErInnloggetViaHnAppUngdom } from '@helsenorge/framework-utils/hn-user';
import { getResourcesFromState, fetchResources } from '@helsenorge/framework-utils/resources';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { HNaddEventListener } from '@helsenorge/framework-utils/web-component/events';
import { fetchWebComp } from '@helsenorge/framework-utils/web-component/fetcher';

import AutoSignout from '../autosignout';
import { isSignedOutAutomatically } from '../autosignout/utils/isSignedOutAutomatically';
import Projects from '../constants/projects';
import KrisepanelContainer from '../krisepanel-container';
import PromopanelContainer from '../promopanel-container';
import { getResources } from '../resources';
import SearchContainer from '../search-container';
import TjenestepanelContainer from '../tjenestepanel-container';
import UserAccessWarning from '../useraccesswarning';
import VarslingspanelContainer from '../varslingspanel-container';
import containerstyles from './../containers.module.scss';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const Forside: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const resources = useAppSelector(
    state => getResourcesFromState(state, Projects.Forside, Languages.NORWEGIAN) as HNCoreFrontendWebForside
  );
  const automaticallySignedOut = isSignedOutAutomatically();

  const [userLoading, setUserLoading] = React.useState(false);
  const webcompheader = document.querySelector('hn-webcomp-header');

  React.useEffect(() => {
    dispatch(fetchResources(Projects.Forside, Languages.NORWEGIAN, getResources));

    if (!automaticallySignedOut) {
      fetchWebComp({
        domain: `${getAssetsUrl()}/fastlegepanel`,
        componentName: 'hn-webcomp-fastlegepanel',
        entryName: 'src/index.tsx',
        includeHelsenorgeCss: true,
      });
    }

    if (isAuthorized() || automaticallySignedOut) {
      document.body.classList.add(containerstyles['blueberry']);
    }
  }, []);

  HNaddEventListener(
    HeaderFooterEvents.setuserloading,
    webcompheader,
    (event: CustomEvent) => {
      setUserLoading(event.detail.userLoading);
    },
    false
  );

  if (!resources) {
    return null;
  }

  if (userLoading) {
    return (
      <div className={`${containerstyles['loader-container']} ${containerstyles['loader-container--tall']}`}>
        <Loader />
      </div>
    );
  }

  if (automaticallySignedOut) {
    return <AutoSignout resources={resources} />;
  }

  if (getErInnloggetViaHnAppUngdom()) {
    return (
      <>
        <KrisepanelContainer />
        <VarslingspanelContainer resources={resources} />
        <UserAccessWarning resources={resources} />
        <PromopanelContainer />
        <SearchContainer resources={resources} />
      </>
    );
  }

  return (
    <React.Fragment>
      <KrisepanelContainer />
      <Spacer size={'2xl'} />
      <VarslingspanelContainer resources={resources} />
      <UserAccessWarning resources={resources} />
      <hn-webcomp-fastlegepanel></hn-webcomp-fastlegepanel>
      <TjenestepanelContainer resources={resources} userLoading={userLoading} />
      <PromopanelContainer />
      <SearchContainer resources={resources} />
      {/* <DigitaltAktiv /> @todo Finnes ikke lenger noe endepunkt som returnerer digitalt aktiv */}
    </React.Fragment>
  );
};
export default Forside;
