import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Languages, { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { getResourcesFromState, fetchResources } from '@helsenorge/framework-utils/resources';
import { hasSamtykke } from '@helsenorge/framework-utils/samtykke-utils';

import ArticlesContainer from '../articles';
import AutoSignout from '../autosignout';
import { isSignedOutAutomatically } from '../autosignout/utils/isSignedOutAutomatically';
import Projects from '../constants/projects';
import FastlegePanelV2 from '../fastlege-v2';
import KrisepanelContainer from '../krisepanel-container';
import OtherContainer from '../other-container';
import { getResources } from '../resources';
import SavnerDuTjenesterV2 from '../savner-du-tjenester-v2';
import SearchBlockContainerV2 from '../search-container-v2';
import UserAccessWarningV2 from '../useraccesswarning-v2';
import containerstyles from './../containers.module.scss';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useGetTilesQuery } from '../store/tilesApi';
import TjenestepanelV2Container from '../tjenestepanel-container-v2';

const Forside: React.FunctionComponent = () => {
  const { data: tilesResponse } = useGetTilesQuery(LanguageLocales.NORWEGIAN);
  const dispatch = useAppDispatch();
  const resources = useAppSelector(
    state => getResourcesFromState(state, Projects.Forside, Languages.NORWEGIAN) as HNCoreFrontendWebForside
  );
  // @todo automaticsignout-sjekken trenger refaktorering ihht react-router-dom-v6
  const automaticallySignedOut = isSignedOutAutomatically();

  React.useEffect(() => {
    dispatch(fetchResources(Projects.Forside, Languages.NORWEGIAN, getResources));

    if (isAuthorized() || automaticallySignedOut) {
      document.body.classList.add(containerstyles['blueberry']);
    }
  }, []);

  if (!resources) {
    return null;
  }

  if (automaticallySignedOut) {
    return <AutoSignout resources={resources} />;
  }

  const showFastlegePanel = !!tilesResponse?.fastlegeTextMessage;
  const showSavnerDuTjenester = !!tilesResponse?.missingServiceWarning;
  const showOtherContainer = showFastlegePanel || showSavnerDuTjenester;

  return (
    <>
      <KrisepanelContainer />
      <UserAccessWarningV2 resources={resources} />
      {hasSamtykke() && <TjenestepanelV2Container resources={resources} />}

      {showOtherContainer && (
        <OtherContainer>
          {showSavnerDuTjenester && (
            <div className="container">
              <div className="row">
                <div className="col col-lg-6">
                  <SavnerDuTjenesterV2
                    title={tilesResponse.missingServiceWarning?.title}
                    description={tilesResponse.missingServiceWarning?.description}
                    bodyTexts={tilesResponse.missingServiceWarning?.bodyTexts}
                    buttonText={tilesResponse.missingServiceWarning?.buttonText}
                  />
                </div>
              </div>
            </div>
          )}
          {showFastlegePanel &&
            tilesResponse.fastlegeTextMessage?.title &&
            tilesResponse.fastlegeTextMessage?.description &&
            tilesResponse.fastlegeTextMessage?.link && (
              <div className="container">
                <div className="row">
                  <div className="col">
                    <FastlegePanelV2
                      title={tilesResponse.fastlegeTextMessage.title}
                      description={tilesResponse.fastlegeTextMessage.description}
                      link={tilesResponse.fastlegeTextMessage.link as unknown as string}
                    />
                  </div>
                </div>
              </div>
            )}
        </OtherContainer>
      )}

      <ArticlesContainer />
      <SearchBlockContainerV2 />
    </>
  );
};
export default Forside;
