import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import { MarkdownType } from '@helsenorge/framework-utils/components/safe-html';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import {
  getHarInnsynssperre,
  getErRepresentasjon,
  getHarReservasjonAktivPaaHelsenorge,
  getErInnloggetViaHnAppUngdom,
  getRepresentertBruker,
} from '@helsenorge/framework-utils/hn-user';
import { hasFulltSamtykke } from '@helsenorge/framework-utils/samtykke-utils';

import ExpanderWarning from './expander-warning';
import Warning from './warning';

interface UserAccessWarningProps {
  resources: HNCoreFrontendWebForside;
}

const UserAccessWarning: React.FC<UserAccessWarningProps> = props => {
  if (getHarInnsynssperre()) {
    return <Warning html={props.resources.notificationPanelInnsynssperre} testId="harInnsynssperre" />;
  }

  if (getHarReservasjonAktivPaaHelsenorge()) {
    const html: MarkdownType =
      getErRepresentasjon() && getRepresentertBruker()
        ? {
            type: 'markdown',
            value: props.resources.digitalt_inaktiv_representasjon_beskrivelse.value.replace(/{navn}/g, getRepresentertBruker() ?? ''),
          }
        : props.resources.digitalt_inaktiv_beskrivelse;

    return (
      <ExpanderWarning
        testId={'harReservasjonAktivPaaHelsenorge'}
        label={
          getErRepresentasjon() && getRepresentertBruker()
            ? props.resources.digitalt_inaktiv_representasjon_tittel.replace(/{navn}/g, getRepresentertBruker() ?? '')
            : props.resources.digitalt_inaktiv_tittel
        }
        html={html}
        resources={props.resources}
      />
    );
  }

  if (getFeatureToggle('UngdomstilgangMedForeldresamtykke') && !hasFulltSamtykke() && !getErRepresentasjon()) {
    return <Warning html={props.resources.notificationPanelFullSamtykke} testId="manglerTjenester" />;
  }

  if (getErInnloggetViaHnAppUngdom()) {
    return <Warning html={props.resources?.idportenlevel3NotificationText} testId="erInnloggetViaHnAppUngdom" />;
  }

  return null;
};

export default UserAccessWarning;
