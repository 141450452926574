import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Title from '@helsenorge/designsystem-react/components/Title';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { getInnloggetFornavn, getErRepresentasjon, getRepresentertBruker } from '@helsenorge/framework-utils/hn-user';

import varslingspanelContainerStyles from './varslingspanelcontainer.module.scss';

interface VarslingspanelContainerProps {
  resources: HNCoreFrontendWebForside;
}

const VarslingspanelContainer: React.FC<VarslingspanelContainerProps> = ({ resources }) => {
  const getWelcometext = (): React.JSX.Element | undefined => {
    let text = `${resources?.varslingspanelTitle},`;
    let element;

    if (getInnloggetFornavn()) {
      text = `${text} ${getInnloggetFornavn()}`;
      element = getErRepresentasjon() ? (
        <span>
          {text}
          <br />
          {`${resources?.varslingspanelTitleDescription} `}
          {getRepresentertBruker()}
        </span>
      ) : (
        <span>{text}</span>
      );
    }
    return element;
  };

  return isAuthorized() ? (
    <div className={varslingspanelContainerStyles['varslingspanel-container']}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Title
              className={varslingspanelContainerStyles['varslingspanel-container__title']}
              appearance="titleFeature"
              id={getSidetittelId()}
            >
              {getWelcometext()}
            </Title>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default VarslingspanelContainer;
