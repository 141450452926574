import React, { useEffect } from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Tjenestepanel from '@helsenorge/core-cms/tjenestepanel-v2';
import { HNCoreFrameworkNPMJS } from '@helsenorge/core-framework/types/Resources';
import Languages, { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import Projects from '@helsenorge/framework-utils/constants/projects';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';
import { getResourcesFromState, fetchResources } from '@helsenorge/framework-utils/resources';

import { getResources } from '../resources';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useGetTilesQuery } from '../store/tilesApi';

interface Props {
  resources: HNCoreFrontendWebForside;
}

const TjenestepanelContainerV2: React.FunctionComponent<Props> = ({ resources }: Props) => {
  const dispatch = useAppDispatch();
  const frameworkResources = useAppSelector(
    state => getResourcesFromState(state, Projects.FRAMEWORK, Languages.NORWEGIAN) as HNCoreFrameworkNPMJS
  );
  useEffect(() => {
    dispatch(fetchResources(Projects.FRAMEWORK, Languages.NORWEGIAN, getResources));
  }, []);

  const { data } = useGetTilesQuery(LanguageLocales.NORWEGIAN);

  if (!data) {
    return null;
  }

  return (
    <Tjenestepanel
      title={resources.tjenestepanel_title}
      titleId={getSidetittelId()}
      showMore={resources.tjenestepanelVisFlere}
      showLess={resources.tjenestepanelVisFarre}
      sharingStatusText={frameworkResources?.sharing_Status_Delt_Med_Barnet ?? undefined}
      favorites={data?.favoriteTiles}
      groups={data?.tiles}
    />
  );
};

export default TjenestepanelContainerV2;
