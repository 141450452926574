/// <reference types="@helsenorge/framework-utils/types/hn"/>

import { getErInnloggetViaHnApp, getErInnloggetViaHnAppUngdom } from '@helsenorge/framework-utils/hn-user';

//Gjør en sjekk på satt cookie verdi og forteller om innbygger har innlogget via hnApp eller ikke.
export const checkIsAppEntry = (): boolean => getErInnloggetViaHnApp() || getErInnloggetViaHnAppUngdom();

//Returnerer url som redirecter til mobilAppAuth med en re-redirect param til bruk av appen etter fullført innlogging
export const fetchAppEntryRedirect = (): string | undefined => {
  const referrer = document.referrer;
  const appUrl = window.HN?.Rest?.MobilAppOpenAuthorizedWebsession;
  if (!appUrl || appUrl.length === 0) {
    // Om MobilAppOpenAuthorizedWebsession ikke har blitt satt
    return undefined;
  }
  if (referrer.length) {
    return `${appUrl}?url=${referrer}`;
  } else {
    return appUrl;
  }
};
