import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Icon from '@helsenorge/designsystem-react/components/Icon';
import Lock from '@helsenorge/designsystem-react/components/Icons/Lock';
import Logo from '@helsenorge/designsystem-react/components/Logo';
import Title from '@helsenorge/designsystem-react/components/Title';

import Languages from '@helsenorge/core-utils/constants/languages';
import { fetchResources, getResourcesFromState } from '@helsenorge/framework-utils/resources';

import Projects from '../constants/projects';
import { getResources } from '../resources';
import { useAppDispatch, useAppSelector } from '../store/hooks';

import './utlogget.scss';

const UtloggetFraOIDC: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(fetchResources(Projects.Forside, Languages.NORWEGIAN, getResources));
  }, []);
  const resources = useAppSelector(
    state => getResourcesFromState(state, Projects.Forside, Languages.NORWEGIAN) as HNCoreFrontendWebForside
  );

  if (!resources) {
    return null;
  }
  return (
    <div className="container">
      <div className="utlogget-header">
        <div className="utlogget-header__logo">
          <Logo color="black" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">
          <div className={'utlogget-body'}>
            <Title appearance={'title1'} htmlMarkup={'h1'}>
              {resources.signedOut_SignedOut_Title}
            </Title>
            <p>{resources.signedOut_SignedOut_Explanation}</p>
            <span className="utlogget-body__circle">
              <Icon className={'utlogget-body__icon-styling'} color={'white'} size={150} svgIcon={Lock}></Icon>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UtloggetFraOIDC;
