import React from 'react';

import Loader from '@helsenorge/designsystem-react/components/Loader';

import Promopanel from '@helsenorge/core-cms/promopanel';
import { PromoBlock } from '@helsenorge/core-cms/types/entities';
import { get } from '@helsenorge/framework-utils/cms-content-api-service';
import { useSetUserLanguageEvent } from '@helsenorge/framework-utils/hn-language';

import containerstyles from './../containers.module.scss';

interface PromopanelResponse extends Response {
  blocks: Array<PromoBlock>;
}

const PromopanelContainer: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<boolean>(false);
  const [blocks, setBlocks] = React.useState<Array<PromoBlock>>([]);

  const getPromopanel = (): void => {
    get<PromopanelResponse>('promopanel')
      .then(response => {
        setLoading(false);

        if (response?.blocks) {
          setBlocks(response.blocks);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  React.useEffect(() => {
    getPromopanel();
  }, []);

  useSetUserLanguageEvent(() => getPromopanel());

  if (error) {
    return null;
  }

  if (loading) {
    return (
      <div className={`container ${containerstyles['loader-container']}`}>
        <Loader />
      </div>
    );
  }

  return <Promopanel promoblocks={blocks} />;
};

export default PromopanelContainer;
