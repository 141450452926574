import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import SafeHTML, { MarkdownType } from '@helsenorge/framework-utils/components/safe-html';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';

import NotificationPanelWrapper from '../notificationpanel-wrapper';

interface WarningProps {
  html: string | MarkdownType;
  testId?: string;
}

const Warning: React.FC<WarningProps> = (props: WarningProps) => {
  return (
    <NotificationPanelWrapper borderBottom={!getFeatureToggle('NyForside2025')}>
      <NotificationPanel variant="info" size="large" testId={props.testId}>
        <SafeHTML html={props.html} tagName="div" />
      </NotificationPanel>
    </NotificationPanelWrapper>
  );
};

export default Warning;
