import React from 'react';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import { get } from '@helsenorge/framework-utils/cms-content-api-service';
import { useSetUserLanguageEvent } from '@helsenorge/framework-utils/hn-language';

import NotificationPanelWrapper from '../notificationpanel-wrapper';

interface Krisepanel {
  title: string;
  introduction: string;
  url: string;
  urlTitle: string;
}

interface KrisepanelResponse extends Response {
  krisepanel: Krisepanel;
}

const KrisepanelContainer: React.FC = () => {
  const [krisepanel, setKrisepanel] = React.useState<Krisepanel | undefined>();
  const visKrisepanel = !!krisepanel;

  const getKrisepanel = (): void => {
    get<KrisepanelResponse>('krisepanel')
      .then(response => {
        setKrisepanel(response?.krisepanel);
      })
      .catch(() => {
        // Dersom det skjer en feil, logges dette av cms-content-api-service
      });
  };

  React.useEffect(() => {
    getKrisepanel();
  }, []);

  useSetUserLanguageEvent(() => getKrisepanel());

  if (!visKrisepanel) {
    return null;
  }

  return (
    <NotificationPanelWrapper>
      <NotificationPanel variant="error" size="large" label={krisepanel?.title}>
        <span>{krisepanel?.introduction} </span>
        {!!krisepanel?.url && !!krisepanel?.urlTitle && <a href={krisepanel.url}>{krisepanel.urlTitle}</a>}
      </NotificationPanel>
    </NotificationPanelWrapper>
  );
};

export default KrisepanelContainer;
