import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';

import { useGetTilesQuery } from '../store/tilesApi';
import ExpanderWarning from '../useraccesswarning/expander-warning';
import Warning from '../useraccesswarning/warning';

interface UserAccessWarningProps {
  resources: HNCoreFrontendWebForside;
}

const UserAccessWarningV2: React.FC<UserAccessWarningProps> = props => {
  const { data } = useGetTilesQuery(LanguageLocales.NORWEGIAN);

  if (!data) {
    return null;
  }

  if (data.userAccessMessageWarning?.title && data.userAccessMessageWarning?.bodyText) {
    return (
      <ExpanderWarning
        label={data.userAccessMessageWarning.title}
        html={data.userAccessMessageWarning.bodyText}
        resources={props.resources}
      />
    );
  }

  if (data.userAccessMessageWarning?.bodyText) {
    return <Warning html={data.userAccessMessageWarning.bodyText} />;
  }

  return null;
};

export default UserAccessWarningV2;
