import React from 'react';

import styles from './styles.module.scss';

interface OtherContainerProps {
  children: React.ReactNode;
}

// @todo trenger et bedre navn
const OtherContainer: React.FC<OtherContainerProps> = props => {
  return <div className={styles.othercontainer}>{props.children}</div>;
};

export default OtherContainer;
