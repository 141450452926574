import * as React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import SafeHTML, { MarkdownType } from '@helsenorge/framework-utils/components/safe-html';

import NotificationPanelWrapper from '../notificationpanel-wrapper';

import styles from './styles.module.scss';

interface ExpanderWarningProps {
  label: string;
  html: string | MarkdownType;
  resources: HNCoreFrontendWebForside;
  testId?: string;
}

const ExpanderWarning: React.FC<ExpanderWarningProps> = props => {
  return (
    <NotificationPanelWrapper borderBottom>
      <NotificationPanel
        variant="info"
        size="large"
        testId={props.testId}
        label={props.label}
        expanderButtonText={props.resources.se_mindre}
        expanderButtonClosedText={props.resources.se_mer}
        expanderChildren={<SafeHTML html={props.html} tagName="div" className={styles['message-content']} />}
      />
    </NotificationPanelWrapper>
  );
};

export default ExpanderWarning;
