import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import Tjenestepanel from '@helsenorge/core-cms/tjenestepanel';
import { HNCoreFrameworkNPMJS } from '@helsenorge/core-framework/types/Resources';
import Languages from '@helsenorge/core-utils/constants/languages';
import SafeHTML from '@helsenorge/framework-utils/components/safe-html';
import Projects from '@helsenorge/framework-utils/constants/projects';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import { getResourcesFromState, fetchResources } from '@helsenorge/framework-utils/resources';
import { hasSamtykke } from '@helsenorge/framework-utils/samtykke-utils';

import containerstyles from './../containers.module.scss';
import { tileMapper } from './helper';
import {
  fetchTiles,
  getLoading,
  getLoaded,
  getHelsetjenesterTiles,
  getInnsynstjenesterTiles,
  getEksterneTjenesterTiles,
  getError,
} from './tiles';
import NotificationPanelWrapper from '../notificationpanel-wrapper';
import { getResources } from '../resources';
import SavnerDuTjenester from '../savner-du-tjenester';
import { useAppDispatch, useAppSelector } from '../store/hooks';

interface Props {
  resources: HNCoreFrontendWebForside;
  userLoading: boolean;
}

const TjenestepanelContainer: React.FunctionComponent<Props> = ({ resources, userLoading }: Props) => {
  const dispatch = useAppDispatch();
  const frameworkResources = useAppSelector(
    state => getResourcesFromState(state, Projects.FRAMEWORK, Languages.NORWEGIAN) as HNCoreFrameworkNPMJS
  );
  React.useEffect(() => {
    dispatch(fetchTiles());
    dispatch(fetchResources(Projects.FRAMEWORK, Languages.NORWEGIAN, getResources));
  }, []);

  const loading = useAppSelector(getLoading);
  const loaded = useAppSelector(getLoaded);
  const error = useAppSelector(getError);
  const helsetjenester = useAppSelector(getHelsetjenesterTiles);
  const innsynstjenester = useAppSelector(getInnsynstjenesterTiles);
  const eksterneTjenester = useAppSelector(getEksterneTjenesterTiles);

  if ((loading && !loaded) || userLoading) {
    return (
      <div className={containerstyles['loader-container']}>
        <Loader testId="tjenestepanel-container-loader" />
      </div>
    );
  }

  const tiles = !!helsetjenester && !!innsynstjenester ? helsetjenester.concat(innsynstjenester) : [];

  return (
    <>
      {error && (
        <NotificationPanelWrapper>
          <NotificationPanel variant="error" size="large" testId="tjenestepanel-container-alert-panel">
            {error.Title && <SafeHTML html={error.Title} />}
            {error.Body}
          </NotificationPanel>
        </NotificationPanelWrapper>
      )}

      {hasSamtykke() && (
        <Tjenestepanel
          visFlereButtonText={resources.tjenestepanelVisFlere}
          visFarreButtonText={resources.tjenestepanelVisFarre}
          fliser={tiles.filter(tile => tile.Title !== 'Behandlingsplan' || getFeatureToggle('ViseBehandlingsplan')).map(tileMapper)}
          eksterneFliser={eksterneTjenester ? eksterneTjenester.map(tileMapper) : []}
          eksterneFliserHeader={resources.tjenestepanelEksterne}
          visAlleFliser
          sharingStatusText={frameworkResources?.sharing_Status_Delt_Med_Barnet ?? undefined}
        >
          <div className="row">
            <div className="col col-lg-6">
              <SavnerDuTjenester resources={resources} />
            </div>
          </div>
        </Tjenestepanel>
      )}
    </>
  );
};

export default TjenestepanelContainer;
