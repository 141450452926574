import { Flis } from '@helsenorge/core-cms/types/entities';
import { Tile } from '@helsenorge/framework-utils/types/entities';

export const tileMapper = (tile: Tile): Flis => {
  return {
    title: tile.Title,
    introduction: tile.Description,
    linkUrl: tile.Link,
    iconName: tile.Image,
    highlight: !!tile.Highlight,
    delAvForeldresamtykke: tile.DelAvForeldresamtykke,
  };
};
