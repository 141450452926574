import SearchBlock from '@helsenorge/core-cms/search/block';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';

import { useGetForsideQuery } from '../store/contentApi';

const SearchBlockContainerV2: React.FC = () => {
  const { data: forside } = useGetForsideQuery(LanguageLocales.NORWEGIAN);

  if (!forside) {
    return null;
  }

  return (
    <SearchBlock
      title={forside.search.title}
      searchInputId={'globalSearch'}
      searchUrl={forside.search.searchUrl}
      link={forside.search.linkUrl}
      linkText={forside.search.linkText}
      imageUrl={forside.search.imageUrl}
      imageAltText={forside.search.imageAltText}
    />
  );
};

export default SearchBlockContainerV2;
