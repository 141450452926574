import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import SearchBlock from '@helsenorge/core-cms/search/block';
import { getHelsenorgeUrl } from '@helsenorge/framework-utils/hn-proxy-service';

interface Props {
  resources: HNCoreFrontendWebForside;
}

const SearchContainer: React.FunctionComponent<Props> = ({ resources }: Props) => (
  <SearchBlock
    title={resources.searchContainerTitle}
    link={`${getHelsenorgeUrl()}${resources.searchContainerLink}`}
    linkText={resources.searchContainerAnchor}
    label={resources.searchContainerLabel}
    searchUrl={'/sok/'}
    searchInputId={'globalSearch'}
  />
);

export default SearchContainer;
