import React from 'react';

import PromoPanel from '@helsenorge/designsystem-react/components/PromoPanel';

interface FastlegePanelProps {
  title: string;
  description: string;
  link: string;
}

const FastlegePanelV2: React.FC<FastlegePanelProps> = props => {
  return (
    <PromoPanel color="cherry" href={props.link} title={props.title} illustration="Doctor">
      {props.description}
    </PromoPanel>
  );
};

export default FastlegePanelV2;
