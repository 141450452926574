import React from 'react';

import { HNCoreFrontendWebForside } from '../types/Resources';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import LockIcon from '@helsenorge/designsystem-react/components/Icons/Lock';
import Title from '@helsenorge/designsystem-react/components/Title';

import { checkIsAppEntry, fetchAppEntryRedirect } from './utils/appEntryHelper';

import sessionstyles from './styles.module.scss';

interface AutoSignoutProps {
  resources: HNCoreFrontendWebForside;
}

const AutoSignout: React.FunctionComponent<AutoSignoutProps> = ({ resources }: AutoSignoutProps) => {
  const isAppEntry = checkIsAppEntry();

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">
          <div className={sessionstyles['signedout__innerwrapper']}>
            <Title appearance={'titleFeature'} htmlMarkup={'h1'}>
              {resources.inactivity_SignedOut_Title}
            </Title>

            <div className={sessionstyles['signedout__buttonwrapper']}>
              {isAppEntry ? null : (
                <Button htmlMarkup={'a'} wrapperClassName={sessionstyles['signedout__button']} href={'/'}>
                  {resources.inactivity_SignedOut_Button_GoToRoot}
                </Button>
              )}
              <Button
                htmlMarkup={'a'}
                wrapperClassName={sessionstyles['signedout__button']}
                href={isAppEntry ? fetchAppEntryRedirect() || resources.inactivity_LoginUrl : resources.inactivity_LoginUrl}
              >
                <Icon svgIcon={LockIcon} />
                {resources.inactivity_SignedOut_Button_SignInAgain}
              </Button>
            </div>
            <div className={sessionstyles['signedout__illustration']}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AutoSignout;
